import React from 'react'
import toolState from "../store/toolState";

const SettingBar = () => {
    return (
        <div className="setting-bar">
                <input onChange={e => { toolState.setStrokeColor(e.target.value) }} id="stroke-color" type="color" />
            <div className='width-container'>
                        <div className='width-choose' onClick={ () => {toolState.setLineWidth(2)}}></div>
                        <div className='width-choose' onClick={ () => {toolState.setLineWidth(3)}}></div>
                        <div className='width-choose' onClick={ () => {toolState.setLineWidth(5)}}></div>
                        <div className='width-choose' onClick={ () => {toolState.setLineWidth(8)}}></div>
                        <div className='width-choose' onClick={ () => {toolState.setLineWidth(20)}}></div>
                </div>
        </div>
    );
};

export default SettingBar