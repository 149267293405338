// client

import React, {useEffect, useRef} from 'react';
import { observer } from 'mobx-react-lite';
import canvasState from '../store/canvasState';
import toolState from '../store/toolState';
import Brush from '../tools/Brush';
import Erase from '../tools/Erase';
import usePartySocket from "partysocket/react";

const Canvas = observer(() => {
  const getRoomId = () => {
    let room = window.location.href;
    let ans = room.slice(room.indexOf('?')+1);
    return ans
  };

  const room = getRoomId();
  console.log("room", room);
    const canvasRef = useRef();
    const socket = usePartySocket({
        room: room,
        onMessage(evt) {
            let msg = JSON.parse(evt.data)
            switch (msg.method) {
                case "draw":
                    drawHandler(msg)
                    break
                case "downloadCanvas":
                  console.log('Download image')
                    const img = new Image()
                    let ctx = canvasRef.current.getContext('2d')
                    img.src = msg.data
                    img.onload = () => {
                        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height)
                        ctx.drawImage(img, 0, 0, canvasRef.current.width, canvasRef.current.height)
                    }
                    break
            }
        },
        onOpen() {
          console.log("connected");
        }
      });

      const drawHandler = (msg) => {
        const figure = msg.figure
        const ctx = canvasRef.current.getContext('2d')
        switch(figure.type) {
          case 'brush':
            Brush.draw(ctx, figure.width, figure.color,  figure.x, figure.y)
            break
          case 'finish':
            ctx.beginPath()
            break
          case 'erase':
            Erase.draw(ctx,figure.color, figure.width, figure.x, figure.y)
            break
  
        }
      }

    useEffect(() => {
        toolState.setTool(new Brush(canvasRef.current, socket));
        canvasState.setCanvas(canvasRef.current);
        canvasState.setSocket(socket);
        let ctx = canvasRef.current.getContext('2d')
    },[])

    return (
    <div className='canvas'>
        <canvas ref={canvasRef} width={600} height={400} />
    </div>
    );
});

export default Canvas;