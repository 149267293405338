import "./styles.css";
import { createRoot } from "react-dom/client";
import Canvas from "./components/Canvas";
import Toolbar from './components/Toolbar';
import SettingBar from './components/SettingBar';



function App() {
  console.log('@@@')
  return (
    <main>
      <div className="container">
        <SettingBar/>
        <Canvas/>
        <Toolbar/>
      </div>
    </main>
  );
}

createRoot(document.getElementById("app")!).render(<App />);
